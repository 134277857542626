export default ({ app, store }, inject) => {
    inject('scrollToElement', (selector, offset = 150, mode = 'smooth') => {
        const targetScrollY = document.querySelector(selector).getBoundingClientRect().top + window.scrollY - offset;

        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ left: 0, top: targetScrollY, behavior: mode });
        } else {
            window.scrollTo(0, targetScrollY);
        }
    });
    inject('downloadFile', (url, fileName) => {
        fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', fileName);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            });
    });
    inject('openAppointmentBookingModal', () => {
        store.commit('layout/setIsAppointmentBookingOpen', true);
        document.body.classList.add('overflow-hidden');
    });
};
