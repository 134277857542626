import dev from './fallback/hu.json';
const merge = require('deepmerge');

export default async ({ $axios, $config }) => {
    return await $axios
        .$get(`${$config.tolgeeURL}/v2/projects/translations/hu?ak=${$config.tolgeeApiKey}`)
        .then((res) => {
            return merge(dev, res.hu);
        });
};
