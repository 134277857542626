import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _165ed14c = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _e164123e = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _91b4ac4c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _617d1ea0 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _4ddb1ead = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e7f9c12e = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _f9f5f6f4 = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _09324f6c = () => interopDefault(import('../pages/dental-problems.vue' /* webpackChunkName: "pages/dental-problems" */))
const _2290a495 = () => interopDefault(import('../pages/quality-guarantee.vue' /* webpackChunkName: "pages/quality-guarantee" */))
const _39e5a846 = () => interopDefault(import('../pages/appointment-booking.vue' /* webpackChunkName: "pages/appointment-booking" */))
const _e9a58a9e = () => interopDefault(import('../pages/implant-oral-surgery.vue' /* webpackChunkName: "pages/implant-oral-surgery" */))
const _f7917c80 = () => interopDefault(import('../pages/invisalign-orthodontic-treatment.vue' /* webpackChunkName: "pages/invisalign-orthodontic-treatment" */))
const _5d5bfb33 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _4454a93e = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _34debe3b = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _1947905d = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _11ccea28 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _7fef8d46 = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _a0ec8dd4 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _7af5f09e = () => interopDefault(import('../pages/premium-dental-care.vue' /* webpackChunkName: "pages/premium-dental-care" */))
const _083f2e6b = () => interopDefault(import('../pages/account/referral-program.vue' /* webpackChunkName: "pages/account/referral-program" */))
const _4baac9ba = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _2e11c36a = () => interopDefault(import('../pages/account/appointments.vue' /* webpackChunkName: "pages/account/appointments" */))
const _26a49e8d = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _86f97ede = () => interopDefault(import('../pages/about/introduction.vue' /* webpackChunkName: "pages/about/introduction" */))
const _01440810 = () => interopDefault(import('../pages/about/career/index.vue' /* webpackChunkName: "pages/about/career/index" */))
const _8e056916 = () => interopDefault(import('../pages/about/trainings/index.vue' /* webpackChunkName: "pages/about/trainings/index" */))
const _686ebdea = () => interopDefault(import('../pages/about/dentists/index.vue' /* webpackChunkName: "pages/about/dentists/index" */))
const _5eca09aa = () => interopDefault(import('../pages/about/testimonials.vue' /* webpackChunkName: "pages/about/testimonials" */))
const _8f7fe290 = () => interopDefault(import('../pages/about/career/preview/_job_preview.vue' /* webpackChunkName: "pages/about/career/preview/_job_preview" */))
const _38cd53de = () => interopDefault(import('../pages/about/trainings/preview/_training_preview.vue' /* webpackChunkName: "pages/about/trainings/preview/_training_preview" */))
const _30108b48 = () => interopDefault(import('../pages/about/career/_job.vue' /* webpackChunkName: "pages/about/career/_job" */))
const _ef80f608 = () => interopDefault(import('../pages/about/dentists/_dentist.vue' /* webpackChunkName: "pages/about/dentists/_dentist" */))
const _cd45db88 = () => interopDefault(import('../pages/about/notifications/_notification.vue' /* webpackChunkName: "pages/about/notifications/_notification" */))
const _51de5848 = () => interopDefault(import('../pages/about/trainings/_training.vue' /* webpackChunkName: "pages/about/trainings/_training" */))
const _4be6284f = () => interopDefault(import('../pages/blog/preview/_article_preview.vue' /* webpackChunkName: "pages/blog/preview/_article_preview" */))
const _5d317b31 = () => interopDefault(import('../pages/about/_content.vue' /* webpackChunkName: "pages/about/_content" */))
const _02d9755f = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _8172b836 = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _28323fc7 = () => interopDefault(import('../pages/services/_service/_price.vue' /* webpackChunkName: "pages/services/_service/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _165ed14c,
    name: "privacy-policy___hu"
  }, {
    path: "/araink",
    component: _e164123e,
    name: "pricing___hu"
  }, {
    path: "/blog",
    component: _91b4ac4c,
    name: "blog___hu"
  }, {
    path: "/elfelejtett-jelszo",
    component: _617d1ea0,
    name: "forgot-password___hu"
  }, {
    path: "/en",
    component: _4ddb1ead,
    name: "index___en"
  }, {
    path: "/felhasznalasi-feltetelek",
    component: _e7f9c12e,
    name: "terms-and-conditions___hu"
  }, {
    path: "/fizetesi-lehetosegek",
    component: _f9f5f6f4,
    name: "payment-methods___hu"
  }, {
    path: "/fogaszati-panaszok",
    component: _09324f6c,
    name: "dental-problems___hu"
  }, {
    path: "/garancia",
    component: _2290a495,
    name: "quality-guarantee___hu"
  }, {
    path: "/idopontfoglalas",
    component: _39e5a846,
    name: "appointment-booking___hu"
  }, {
    path: "/implantatum-szajsebeszet",
    component: _e9a58a9e,
    name: "implant-oral-surgery___hu"
  }, {
    path: "/invisalign-fogszabalyozas",
    component: _f7917c80,
    name: "invisalign-orthodontic-treatment___hu"
  }, {
    path: "/jelszovaltoztatas",
    component: _5d5bfb33,
    name: "change-password___hu"
  }, {
    path: "/jogi-nyilatkozat",
    component: _4454a93e,
    name: "legal-notice___hu"
  }, {
    path: "/kapcsolat",
    component: _34debe3b,
    name: "contact___hu"
  }, {
    path: "/kereses",
    component: _1947905d,
    name: "search___hu"
  }, {
    path: "/leiratkozas",
    component: _11ccea28,
    name: "unsubscribe___hu"
  }, {
    path: "/munkaink",
    component: _7fef8d46,
    name: "works___hu"
  }, {
    path: "/szolgaltatasok",
    component: _a0ec8dd4,
    name: "services___hu"
  }, {
    path: "/en/appointment-booking",
    component: _39e5a846,
    name: "appointment-booking___en"
  }, {
    path: "/en/blog",
    component: _91b4ac4c,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _5d5bfb33,
    name: "change-password___en"
  }, {
    path: "/en/contact",
    component: _34debe3b,
    name: "contact___en"
  }, {
    path: "/en/dental-problems",
    component: _09324f6c,
    name: "dental-problems___en"
  }, {
    path: "/en/forgot-password",
    component: _617d1ea0,
    name: "forgot-password___en"
  }, {
    path: "/en/invisalign-orthodontic-treatment",
    component: _f7917c80,
    name: "invisalign-orthodontic-treatment___en"
  }, {
    path: "/en/legal-notice",
    component: _4454a93e,
    name: "legal-notice___en"
  }, {
    path: "/en/payment-methods",
    component: _f9f5f6f4,
    name: "payment-methods___en"
  }, {
    path: "/en/premium-dental-care",
    component: _7af5f09e,
    name: "premium-dental-care___en"
  }, {
    path: "/en/pricing",
    component: _e164123e,
    name: "pricing___en"
  }, {
    path: "/en/privacy-policy",
    component: _165ed14c,
    name: "privacy-policy___en"
  }, {
    path: "/en/quality-guarantee",
    component: _2290a495,
    name: "quality-guarantee___en"
  }, {
    path: "/en/search",
    component: _1947905d,
    name: "search___en"
  }, {
    path: "/en/services",
    component: _a0ec8dd4,
    name: "services___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _e7f9c12e,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/unsubscribe",
    component: _11ccea28,
    name: "unsubscribe___en"
  }, {
    path: "/en/works",
    component: _7fef8d46,
    name: "works___en"
  }, {
    path: "/profilom/ajalnasok",
    component: _083f2e6b,
    name: "account-referral-program___hu"
  }, {
    path: "/profilom/beallitasok",
    component: _4baac9ba,
    name: "account-settings___hu"
  }, {
    path: "/profilom/idopontjaim",
    component: _2e11c36a,
    name: "account-appointments___hu"
  }, {
    path: "/profilom/vezerlopult",
    component: _26a49e8d,
    name: "account-dashboard___hu"
  }, {
    path: "/rolunk/bemutatkozas",
    component: _86f97ede,
    name: "about-introduction___hu"
  }, {
    path: "/rolunk/karrier",
    component: _01440810,
    name: "about-career___hu"
  }, {
    path: "/rolunk/kepzeseink",
    component: _8e056916,
    name: "about-trainings___hu"
  }, {
    path: "/rolunk/orvosaink",
    component: _686ebdea,
    name: "about-dentists___hu"
  }, {
    path: "/rolunk/velemenyek",
    component: _5eca09aa,
    name: "about-testimonials___hu"
  }, {
    path: "/en/about/career",
    component: _01440810,
    name: "about-career___en"
  }, {
    path: "/en/about/dentists",
    component: _686ebdea,
    name: "about-dentists___en"
  }, {
    path: "/en/about/introduction",
    component: _86f97ede,
    name: "about-introduction___en"
  }, {
    path: "/en/about/testimonials",
    component: _5eca09aa,
    name: "about-testimonials___en"
  }, {
    path: "/en/about/trainings",
    component: _8e056916,
    name: "about-trainings___en"
  }, {
    path: "/en/account/appointments",
    component: _2e11c36a,
    name: "account-appointments___en"
  }, {
    path: "/en/account/dashboard",
    component: _26a49e8d,
    name: "account-dashboard___en"
  }, {
    path: "/en/account/referral-program",
    component: _083f2e6b,
    name: "account-referral-program___en"
  }, {
    path: "/en/account/settings",
    component: _4baac9ba,
    name: "account-settings___en"
  }, {
    path: "/en/about/career/preview/:job_preview",
    component: _8f7fe290,
    name: "about-career-preview-job_preview___en"
  }, {
    path: "/en/about/trainings/preview/:training_preview",
    component: _38cd53de,
    name: "about-trainings-preview-training_preview___en"
  }, {
    path: "/en/about/career/:job",
    component: _30108b48,
    name: "about-career-job___en"
  }, {
    path: "/en/about/dentists/:dentist",
    component: _ef80f608,
    name: "about-dentists-dentist___en"
  }, {
    path: "/en/about/notifications/:notification",
    component: _cd45db88,
    name: "about-notifications-notification___en"
  }, {
    path: "/en/about/trainings/:training",
    component: _51de5848,
    name: "about-trainings-training___en"
  }, {
    path: "/en/blog/preview/:article_preview",
    component: _4be6284f,
    name: "blog-preview-article_preview___en"
  }, {
    path: "/rolunk/karrier/preview/:job_preview",
    component: _8f7fe290,
    name: "about-career-preview-job_preview___hu"
  }, {
    path: "/rolunk/kepzeseink/preview/:training_preview",
    component: _38cd53de,
    name: "about-trainings-preview-training_preview___hu"
  }, {
    path: "/blog/preview/:article_preview",
    component: _4be6284f,
    name: "blog-preview-article_preview___hu"
  }, {
    path: "/en/about/:content",
    component: _5d317b31,
    name: "about-content___en"
  }, {
    path: "/en/blog/:article",
    component: _02d9755f,
    name: "blog-article___en"
  }, {
    path: "/en/services/:service",
    component: _8172b836,
    name: "services-service___en"
  }, {
    path: "/rolunk/ertesitesek/:notification",
    component: _cd45db88,
    name: "about-notifications-notification___hu"
  }, {
    path: "/rolunk/karrier/:job",
    component: _30108b48,
    name: "about-career-job___hu"
  }, {
    path: "/rolunk/kepzeseink/:training",
    component: _51de5848,
    name: "about-trainings-training___hu"
  }, {
    path: "/rolunk/orvosaink/:dentist",
    component: _ef80f608,
    name: "about-dentists-dentist___hu"
  }, {
    path: "/en/services/:service/:price",
    component: _28323fc7,
    name: "services-service-price___en"
  }, {
    path: "/blog/:article",
    component: _02d9755f,
    name: "blog-article___hu"
  }, {
    path: "/rolunk/:content",
    component: _5d317b31,
    name: "about-content___hu"
  }, {
    path: "/szolgaltatasok/:service",
    component: _8172b836,
    name: "services-service___hu"
  }, {
    path: "/szolgaltatasok/:service/:price",
    component: _28323fc7,
    name: "services-service-price___hu"
  }, {
    path: "/",
    component: _4ddb1ead,
    name: "index___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
